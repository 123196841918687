import $ from 'jquery';

if (document.querySelector('.dropdown-toggle')) {
    $(document).on('click', '.dropdown-toggle', function() {
        const $this = $(this);
        const targetId = $this.data('target');
        $('.dropdown').removeClass('show');
        $(`.dropdown#${targetId}`).toggleClass('show');
    });

    $(document).on('click', function ({ target }) {
        if (!$(target).closest('.dropdown-toggle').length) {
            $('.dropdown').removeClass('show');
        }
    });
}
