import $ from 'jquery';

let $authCodeInput = $('#_auth_code');
if ($authCodeInput) {
    $authCodeInput.on('input', function() {
        $this = $(this);
        const authCode = $this.val();
        const authCodeLength = authCode.length;
        if (authCodeLength >= $this.attr('maxlength')) {
            $this.blur();
        }
        $this.closest('.position-relative').find('span').map(function(index, element) {
            if (index < authCodeLength) {
                $(element).removeClass('border-grey');
            } else {
                $(element).addClass('border-grey');
            }
        });
    });
}
